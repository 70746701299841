th.ant-table-cell.column-name {
  font-weight: bold;
  font-size: 14px;
}

.column-name {
  padding-left: 20px !important;
}

th.ant-table-cell.column-name::before {
  display: none;
}

.ant-table-cell.column-name:nth-child(6) {
  /* border: 3px solid red; */
  padding-right: 20px !important;
}

td.ant-table-column-sort {
  background-color: #fff;
}
