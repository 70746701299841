.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.p-fileupload-choose {
  background: #11a557 !important;
  border: none !important;
}

.p-button {
  background: #11a557 !important;
  border: none !important;
}

.p-button-danger {
  color: #fff !important;
}
