@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "antd-table-infinity/index.css";

body {
  font-family: "Mulish";
}

.ant-modal-mask {
  z-index: 1010 !important;
  backdrop-filter: blur(2px);
}

.ant-modal-wrap {
  z-index: 1011 !important;
}

.modal-default {
  .ant-modal-header,
  .ant-modal-footer {
    // border: none !important;
  }

  .ant-modal-content {
    border-radius: 16px !important;
  }

  .ant-modal-title {
    font-size: 20px;
  }

  .ant-modal-body {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .ant-modal-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .ant-modal-footer {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.scroll-bar-hidden::-webkit-scrollbar {
  display: none;
}

.scroll-bar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chakra-progress > div {
  border-radius: 8px;
}

.swal2-cancel-white {
  background-color: white !important;
  color: #777 !important;
  border: 1px solid #777 !important;
  padding-left: 32px !important;
  font-size: 13px !important;
  position: relative;

  &:hover {
    background-color: white !important;
  }

  &::before {
    content: "";
    background-image: url("../images/icons/icon-off-user.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 6px;
  }
}

.swal2-cancel-primary {
  position: relative;
  font-size: 13px !important;
  padding-left: 32px !important;

  &::before {
    content: "";
    background-image: url("../images/icons/arrow-left.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 6px;
  }
}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 0px !important;
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      cell-horizontal-border: solid #c0c4cc,
    )
  );

  .ag-header {
    background-color: #ebeaea !important;
  }

  .ag-header-cell,
  .ag-cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .ag-header-cell::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 15px;
    left: 0px;
    border-right: 1px solid #c0c4cc !important;
  }

  .ag-cell {
    border-right: 1px solid #c0c4cc !important;
  }

  .ag-row:nth-child(even) .ag-cell {
    background-color: #faf8f8;
  }

  .ag-row-selected .ag-cell {
    background-color: #dcdde1 !important;
  }

  .ag-row-hover {
    border: 1px solid #009540 !important;
    background-color: transparent !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #009540 !important;
  border-color: #009540 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #009540 !important;
}

.ant-table {
  .ant-table-thead {
    & > tr > th {
      background-color: #ebeaea !important;
      height: 43px !important;
    }
  }

  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th,
  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-left: none !important;
    border-right: none !important;
    border-top: 1px solid #707070 !important;
    border-bottom: 1px solid #babfc7 !important;
  }

  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr,
  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr {
    & > th:first-child {
      border-left: 1px solid #707070 !important;
    }

    & > th:last-child {
      border-right: 1px solid #707070 !important;
    }
  }

  &.ant-table-bordered
    > .ant-table-container
    .ant-table-body
    > table
    > tbody
    > tr
    > td,
  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right-color: #babfc7 !important;
    border-bottom-color: #babfc7;
  }

  &.ant-table-bordered
    > .ant-table-container
    .ant-table-body
    > table
    > tbody
    > tr:last-child
    > td {
    border-bottom: none !important;
  }

  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr:last-child
    > td {
    border-bottom: 1px solid #707070;
  }

  &.ant-table-bordered
    > .ant-table-container
    .ant-table-body
    > table
    > tbody
    > tr.row-selected
    > td {
    background-color: #d8d8d8 !important;
  }

  &.ant-table-bordered
    > .ant-table-container
    .ant-table-body
    > table
    > tbody
    > tr:not(:hover)
    > td:first-child,
  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr:not(:hover)
    > td:first-child {
    border-left: 1px solid #707070 !important;
  }

  &.ant-table-bordered
    > .ant-table-container
    .ant-table-body
    > table
    > tbody
    > tr:not(:hover)
    > td:last-child,
  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr:not(:hover)
    > td:last-child {
    border-right: 1px solid #707070 !important;
  }

  &.ant-table-bordered > .ant-table-footer {
    border-right: 1px solid #707070 !important;
    border-left: 1px solid #707070 !important;
    border-bottom: 1px solid #707070 !important;
    border-top: 1px solid #babfc7 !important;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #c0c4cc !important;
    height: 10px !important;
  }

  .ant-table-tbody > tr.ant-table-row > td {
    border-top: 1px solid transparent;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td:first-child {
    border-left: 1px solid #009540 !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td:last-child {
    border-right-color: #009540 !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    border-top-color: #009540 !important;
    border-bottom-color: #009540 !important;
    background-color: transparent !important;
  }

  .ant-table-tbody
    > tr.ant-table-row:not(.ant-table-row-level-1):nth-child(even)
    > td {
    background-color: #faf8f8 !important;
  }

  &.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th {
    color: black !important;
    font-weight: bold !important;
  }

  .ant-table-row-expand-icon {
    border: none !important;
    transform: none !important;
    background: transparent !important;

    &::before {
      content: ">";
      top: 0;
      left: 12px;
      transform: none !important;
      height: 0 !important;
      transition: none;
      color: black !important;
    }

    &::after {
      display: none;
    }
  }

  .ant-table-container
    .ant-table-body
    > table
    > tbody
    > tr
    > td.ant-table-row-expand-icon-cell,
  .ant-table-container
    .ant-table-body
    > table
    > tbody
    > tr
    > td.ant-table-row-expand-icon-cell
    + td {
    border-right: none !important;
  }

  [class*="ant-table-row-level-1"] {
    display: none !important;
  }

  .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded::before {
    top: 10px;
    left: 4px;
    transform: rotate(-90deg) !important;
  }
}
